<template>
  <UiPopup
    :model-value="modelValue"
    size="extraBig"
    class="bg-black-100/50"
    :popup-classes="isMobile ? '[&>div]:p-0 !bg-transparent' : 'p-8 [&>div]:p-0'"
    hidden
    hide-close-button
    @update:model-value="emits('update:modelValue', false)"
  >
    <template v-if="isMobile" #outside>
      <button class="absolute right-4 top-4" @click="emits('update:modelValue', false)">
        <UiIcon name="big-close" class="text-white" />
      </button>
    </template>
    <template v-if="!isMobile" #header>
      <div class="mb-4 flex items-center justify-between text-left">
        <div>
          <h4 class="mb-2">{{ item.title.en }}</h4>
          <p class="text-body text-black-70">
            Last update {{ format(new Date(item.updated_at), 'dd MMMM yyyy hh:mm') }}
          </p>
        </div>
        <button @click="emits('update:modelValue', false)">
          <UiIcon name="big-close"></UiIcon>
        </button>
      </div>
    </template>
    <div class="relative">
      <client-only>
        <Swiper
          ref="swiper"
          navigation
          :pagination="{
            type: 'fraction',
          }"
          :auto-height="true"
          :modules="[SwiperNavigation, SwiperPagination]"
          class="!overflow-y-visible"
          @slide-change="updateCurrentIndex"
        >
          <SwiperSlide
            v-for="(image, index) in gallery"
            :key="index"
            class="!md:h-[calc(100vh_-_200px)] cursor-pointer select-none"
          >
            <img :src="image" :alt="`preview-${index}`" class="size-full object-cover" />
          </SwiperSlide>
        </Swiper>
      </client-only>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { format } from 'date-fns'
import type { PropertyTypes } from '@/types'

const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: boolean
  item: PropertyTypes.PropertyDetailed
  gallery: string[]
}

defineProps<Props>()

const { smaller } = useBreakpoints(useGetBreakpoints())
const isMobile = smaller('xs')
const currentIndex = ref(1)

const updateCurrentIndex = (swiper: Swiper) => {
  currentIndex.value = swiper.activeIndex + 1
}
</script>

<style scoped lang="scss">
:deep() {
  .swiper {
    &-button-prev,
    &-button-next {
      @apply -top-7 mt-0 size-8 -translate-y-full rounded-lg bg-black-05;
      @media screen and (max-width: 1024px) {
        @apply top-32;
      }
      &:after {
        @apply text-lg text-black-100;
      }
      &.swiper-button-disabled {
        opacity: 1;
        &:after {
          @apply text-black-40;
        }
      }
    }
    &-button-prev {
      @apply right-40;
      left: initial !important;
      @media screen and (max-width: 1024px) {
        left: 16px !important;
      }
    }
    &-button-next {
      @apply right-12;
      @media screen and (max-width: 1024px) {
        right: 16px !important;
      }
    }
    &-pagination-fraction {
      @apply -top-8 right-[88px] w-16 -translate-y-full;
      font-weight: 600;
      font-size: 16px;
      line-height: 22.4px;
      left: initial !important;
      bottom: initial !important;
      @media screen and (max-width: 1024px) {
        position: fixed;
        top: 40px;
        left: 8px !important;
        color: white;
      }
    }
  }
}
</style>
